---
title: Opera 大佬真厉害
date: 2018-05-30 11:23:54
tags: 
- 科学
categories: Path
---

你还在为临时上下油管而手忙脚乱的打开 ss 或者 vpn 而烦恼吗，你还在为 ss 下不停点掉汽车，店铺门面而向证明谷狗自己不是机器人吗？快投入 opera 的怀抱吧。

## 关键词：
- 电脑区域设置为：美国
- Opera 里打开 vpn

然后就开始享受吧！

![](https://i.imgur.com/FpKvYKu.png)
![](https://i.imgur.com/Tp0YBDL.png)
![](https://i.imgur.com/tHP5hAq.png)

大家好像都不知道这个小秘密呢！Opera SUGE(*用日语发音状*)！