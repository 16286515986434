---
title: 妈妈呀谷歌好可怕
date: 2016-05-29 23:45:43
tags:
- 随笔
categories: Life
---

今天无意间进入了时间轴的谷歌页面，然后就被吓到了。妈妈呀，姑姑把我所有去过的地方都记录下来了。以前用iPhone的 时候也没有被定位的这么准，iPhone也就大概定位一个位置，高德地图还显示不出来。

![](http://i.imgur.com/Y2EZzH1.png "iPhone 常去地点")

![](http://i.imgur.com/mtTUPjX.png "iPhone 常去地点")

没想到谷歌直接就玩跟踪了。而且国内也跟的这么溜。我对隐私什么也不是那么敏感，偶尔回过头来看看自己去过哪里也挺有趣的，但还是不免有些后怕，连在墙内都被跟了，那墙外的自由民真的是坠落的精准啊。替他们打抱不平！

<!--more-->

![](http://i.imgur.com/d1idGPu.png "姑姑为啥盯着你")

![](http://i.imgur.com/gOPB6AW.png "姑姑还是盯着你")

![](http://i.imgur.com/rYzxfzO.png "姑姑一直盯着你")

![](http://i.imgur.com/3EyUPdx.png "姑姑使劲盯着你")

![](http://i.imgur.com/kWVxhIt.png "姑姑盯着你")

![](http://i.imgur.com/IFBfZTm.png "姑盯着你")

![](http://i.imgur.com/boel4iA.png "盯着你")

![](http://i.imgur.com/H2mNJHS.png "着你")

![](http://i.imgur.com/17EkRYH.png "你")
