---
title: 杀回二次元
date: 2014-09-18 17:49:00
tags:
- 随笔
categories: Life
---

![](http://i.imgur.com/DlKxcUJ.jpg 'img')

以前的我，大概一直都把二次元作为现充的避风港，不管无聊还是忙的半死就爱一头往里钻，面对一片茫茫补番之路一直都保持着那颗高傲的御宅心，还有誓死不罢休之志。呆寝室的日子里，平平淡淡波澜不惊，不以漫喜，也不以漫悲，一句话——就是无忧无虑没烦恼。然而，环境与生活似乎总是在变化，在社团当上点小小职务后，我似乎要开始揣测起现充这个怪兽了。

一瞬间，我以为自己已经蜕变了，蜕变的不那么宅，蜕变的喜欢现充，迷恋现充，于是，脱去宅的外衣，我开始过上了现充的日子。我也曾被我的改变所吃惊，但相信成长的我还是决定试着尝试下那些现充人的日子，品尝下别人口中相比与二次元“幼稚”背后的成熟的现充生活。

听了许多，看了许多，也亲身经历了许多。不禁感慨这难道就是传说中令御宅闻风丧胆的现充？！无暇为拉他人入坑感到亢奋，不再肯付出百般经历安利各种冷番，渐渐停催zx讨论组的kulucolo稿。（甚至连神经病番grasslip都放下了。慢慢的，心中仿佛少了那一丝二次元牵挂，生活还是照样过着，二次元也还是照样播着。是啊，二次元再怎么样了也改变不了我怎么样了，现在想起来我竟然还一度考虑当初那个入二次元坑的目的。。。为什么要有目的，一颗赤子心，一段御宅魂，还有那不泯的童心。虽然只有区区几个动漫死党，B站那不是还有一大帮“小学生”吗；虽然二次元可能改变不了什么现状，但谁知道呢，谁知道二次元在我未来人生中扮演什么样的角色；一番不补何来人类补完计划。

重拾放下二次元，这一次，握的更紧，因为我的背后永远有那个宅气重重的四人组和陪伴我的ZX讨论组。想我脱宅，你们还太嫩啦
