---
title: Zerochan Anime Searcher UWP
date: 2017-01-07 11:33:59
tags:
- UWP
- Csharp
categories: Rice
---
![](https://i.imgur.com/k9st23o.png)

**[Zerochan Searcher](https://www.microsoft.com/zh-cn/store/p/zerochan-searcher/9msxs01t5lsl)** 是一个基于动漫图片网站 Zerochan.net 的第三方应用。由 KuluColo 计划组制作支持。建议使用桌面端。图片的版权归原作者和 Zerochan.net 所有。

**[Zerochan Searcher](https://www.microsoft.com/zh-cn/store/p/zerochan-searcher/9msxs01t5lsl)** is a third-party UWP application for searching, downloading anime picture based on Zerochan.net. It supports multiple download modes once right-clicking on the picture listview. We recommend you to use Zerochan Searcher in Desktop Client. Images are copyrighted by the original author and owned by Zerochan.net.

_Update: 已于 2018 年失效，Zerochan 网站增加了图片链接获取限制 / The application expired in 2018 due to website limitation. Please use the browser to reach the original website_


- 5/27/2017 `版本更新 2.0.0.0`: 加入“最爱”书签(测试)功能
- 5/27/2017 `Version Update 2.0.0.0`: Add the Favorite Beta features. 

![](https://i.imgur.com/0qn6m4e.jpg)

![](https://i.imgur.com/e84cST6.jpg)

<!--more-->

- 5/10/2017 `版本更新 1.5.2.0`: 日常小更新，优化界面，解决一个小bug
- 5/10/2017 `Version Update 1.5.2.0`: Fixed one bug; Tweaked the interface.

![](https://i.imgur.com/DGpFDEi.jpg)

- 5/4/2017 `版本更新 1.5.0.0`: 增加滑动、拖动等针对平板触摸设备的优化
- 5/4/2017 `Version Update 1.5.0.0`: Increase the swipe and drag gesture for Tablet users.

<video src="https://i.imgur.com/iPisT45.mp4" autoplay="" muted="" loop="">
Your Browser does not support see this gif file, upgrade to Firefox 3.5+, Google Chrome, or Safari.
</video></br>

---
- [x] Support view, search and download functions.／支持查看搜索和下载的基本功能
- [x] Open multiple download mode when right click on the picture list.／在图片列表中右击可开启多选下载模式
- [x] One daily word by Yiyan API.／调用一言API
- [x] Increase the search correction／提高搜索栏的自动检索和转换（目前仅支持罗马音）
- [x] Support fullscreen viewer mode.／支持全屏查看
- [x] Add tags on each picture.／增加每张图地标签
- [x] Fix for the mobile client. (still, need to optimized)／适配于moblie手机端 (仍然需要改进)
- [ ] Auto set wallpaper. /设置自动壁纸

---
### Selected Screenshots

<video src="https://i.imgur.com/YSeJeOF.mp4" autoplay="" muted="" loop="">
Your Browser does not support see this gif file, upgrade to Firefox 3.5+, Google Chrome, or Safari.
</video></br>

#### Desktop ／桌面端
![](https://i.imgur.com/66LLofW.png)

![](https://i.imgur.com/NWrIEsy.png)

#### Mobile ／手机端
![](http://i.imgur.com/lAXpqgZ.png)

![](https://i.imgur.com/TV8DyU6.jpg)
---

## Code / 代码开源

vince加紧整理中。。。

[github](https://github.com/vincecao/ZeroChan-Searcher-in-UWP)

Still Pending...

## Want to Donate / 考虑捐赠？🍕

![](https://i.imgur.com/61sMmYY.png)

### Thanks List / 捐赠贡献名单

 - 5/4/2017 一位匿名的小伙伴为格子衬衫续了`5`大洋