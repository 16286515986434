import { easeInOut } from '../../easing/ease.mjs';
import { interpolate } from '../../utils/interpolate.mjs';
import { defaultOffset } from '../../utils/offsets/default.mjs';
import { convertOffsetToTimes } from '../../utils/offsets/time.mjs';
import { isEasingArray, easingDefinitionToFunction } from '../utils/easing.mjs';

function defaultEasing(values, easing) {
    return values.map(() => easing || easeInOut).splice(0, values.length - 1);
}
function keyframes({ keyframes: keyframeValues, ease = easeInOut, times, duration = 300, }) {
    keyframeValues = [...keyframeValues];
    /**
     * Easing functions can be externally defined as strings. Here we convert them
     * into actual functions.
     */
    const easingFunctions = isEasingArray(ease)
        ? ease.map(easingDefinitionToFunction)
        : easingDefinitionToFunction(ease);
    /**
     * This is the Iterator-spec return value. We ensure it's mutable rather than using a generator
     * to reduce GC during animation.
     */
    const state = {
        done: false,
        value: keyframeValues[0],
    };
    /**
     * Create a times array based on the provided 0-1 offsets
     */
    const absoluteTimes = convertOffsetToTimes(
    // Only use the provided offsets if they're the correct length
    // TODO Maybe we should warn here if there's a length mismatch
    times && times.length === keyframeValues.length
        ? times
        : defaultOffset(keyframeValues), duration);
    function createInterpolator() {
        return interpolate(absoluteTimes, keyframeValues, {
            ease: Array.isArray(easingFunctions)
                ? easingFunctions
                : defaultEasing(keyframeValues, easingFunctions),
        });
    }
    let interpolator = createInterpolator();
    return {
        next: (t) => {
            state.value = interpolator(t);
            state.done = t >= duration;
            return state;
        },
        flipTarget: () => {
            keyframeValues.reverse();
            interpolator = createInterpolator();
        },
    };
}

export { defaultEasing, keyframes };
