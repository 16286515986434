---
title: 该睡觉了
date: 2015-01-10 13:00:00
tags: 
- 随笔
categories: Life
---

昨天帮Micky建了一个小站，当她问我你怎么会的时候，我只是单纯说了一句，我自己学的，从网上。我可以感受到那刻的自己是别人眼中憧憬对象，同时也是那个我自己憧憬的对象。没错，我正在做我喜欢的自己，同时也经历着别人同样痛苦的心路历程。我大概还没从2014年的痛苦中走出来，最近还是有点心情低落，不是因为事情不顺，而是为如何调整心情儿烦恼，对未知的明天憧憬的同时却也害怕，但人就是在幕后都是掩饰那些最平常不过的艰难的动物，被人割去了手指，被人摔断了腿，盒子里的那个人永远不会被观众所察觉，谢幕的鞠躬便足以包含了之前所有的痛楚，Are you watching closely？

诺兰的电影怕是以后会少看了吧，这种电影毕竟多多少少有点费脑子，多看，让我转变心情的只是自己对电影剧情的认识还有单纯的串着时间线，总觉得有一股被导演玩弄于手掌的感觉。人生大概也是被别人玩弄在手掌中，自己却浑然不知，我讨厌这种被玩弄。

我不知道现在是不是真的到了必须出现一个人来让我改变的时候，要想打破我的处境，变革必须发生，快来吧，我所期待的人，变革会打破如今的平衡，会摧毁我曾经拥有的一切吧，然后我将再次建立起属于我自己的王朝。
