---
title: 今天难道是我的幸运日吗
date: 2015-02-01 13:00:00
tags: 
- 随笔
categories: Life
---

白天和老爸去了湖边的老房子，规划拆到老房子家门口就不拆了！一想到人生中又一美好的事物能够继续存在下去了，那还有啥理由不继续快乐的生活下去呢，恩，干完这一票我就回湖边的老家结婚去了吧🙆，好美好醉好开心！

家里的01年的台式在我的坚持不懈之下终于装上了win7，把我所有的音乐资源都扔进去，然后淘两条ddr 1g当个大mp3，就这样的定好了！

日常上网升华自我之时，发现一个很不错的电纸书，kobo touch，便宜小巧好看至极尼玛还能刷安卓，这不是为我量身定做的嘛！我就要定你了，嘿嘿别想逃，趁现在读书劲还没过赶快抓紧先读两本压压惊。

昨天shield 在市场里更新了下Hub，今天竟然能连上北美的stream grid了，接着又想起来前几天别人推荐的组装ps3手柄，otg的线也失而复得，啊啊啊，光是想想都是爽歪歪啊～

不行了，今天有点太美好了，睡前我得好好静一静，这样就再能做一个有趣的梦了😀～Lucky～
