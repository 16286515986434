---
title: 花闲钱自己组了辆车，妈妈再也不用担心我的动手能力
date: 2015-10-27 21:41:59
tags:
- 随笔
categories: Life
---

[![](http://i.imgur.com/pQiikDt.jpg "R901 Vince Type")](https://www.flickr.com/photos/saablancias/21783829773/in/dateposted-public/ 'R901 Vince Type')

![](http://i.imgur.com/lniBrc2.jpg)

花了大概1000这样组了一辆车，

别人送了台R901的老钢架，然后就着手开始改了，`135`入手咸鱼二手的R903 700c轮组， `400`入手咸鱼sora3300八速手变、带前后拨飞轮和链条、Tiagra 4500 牙盘， `62`入手咸鱼捷安特ocr3300车座，`58`入手全新Diacompe长臂C夹刹闸，其他的杂七杂八如轮胎脚架还有座杆大致花费`200`，`150`去捷安特店让店员帮忙组装传动系统.
