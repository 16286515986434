---
title: 签名神软——日文書法
date: 2014-09-11 01:42:38
tags:
- Windows
categories: Path
---

常常会遇到一个需要自己设计签名的场景，日文書法就是这么一个小软件，书法家什么都弱爆了，做个码农才是王道

**附：遇到打开不正常可以尝试兼容设为win7**

一.打开界面

[![打开界面](http://m2.img.srcdd.com/farm5/d/2014/0911/09/107FE4F3B3A4CEACD63ACCEF9208C6B5_LARGE_1223_922.jpeg)](http://m2.img.srcdd.com/farm5/d/2014/0911/09/107FE4F3B3A4CEACD63ACCEF9208C6B5_LARGE_1223_922.jpeg)

二.随手渣签名

[![原稿，渣鼠绘](http://m1.img.srcdd.com/farm5/d/2014/0911/09/54DA3A742C70EC65B635E62BA91E9250_LARGE_751_854.jpeg)](http://m1.img.srcdd.com/farm5/d/2014/0911/09/54DA3A742C70EC65B635E62BA91E9250_LARGE_751_854.jpeg)

三.修改右侧属性，达到各种逆天的效果

[![处理后1](http://m1.img.srcdd.com/farm4/d/2014/0911/09/7A47AF7F9DD6016AFC389C641E5DFF5A_LARGE_751_854.jpeg)](http://m1.img.srcdd.com/farm4/d/2014/0911/09/7A47AF7F9DD6016AFC389C641E5DFF5A_LARGE_751_854.jpeg)[![处理后2](http://m1.img.srcdd.com/farm5/d/2014/0911/09/9C65ACA7ADDA0DB70E7F725966D590C4_LARGE_751_854.jpeg)](http://m1.img.srcdd.com/farm5/d/2014/0911/09/9C65ACA7ADDA0DB70E7F725966D590C4_LARGE_751_854.jpeg)[![处理后3](http://m1.img.srcdd.com/farm5/d/2014/0911/09/6AC8ADB4A02F2F562919EE2BBA20FFD4_LARGE_751_854.jpeg)](http://m1.img.srcdd.com/farm5/d/2014/0911/09/6AC8ADB4A02F2F562919EE2BBA20FFD4_LARGE_751_854.jpeg)[![处理后4](http://m2.img.srcdd.com/farm4/d/2014/0911/09/6113D423636C6082F204CAF349192451_LARGE_751_854.jpeg)](http://m2.img.srcdd.com/farm4/d/2014/0911/09/6113D423636C6082F204CAF349192451_LARGE_751_854.jpeg)

四.另存为导出

![](http://m3.img.srcdd.com/farm5/d/2014/0911/09/861469B90B7065BBE1F4EFAC00F72AF9_LARGE_1226_924.jpeg)

五.分享地址

_**链接: [http://pan.baidu.com/s/1mguUHC0](http://pan.baidu.com/s/1mguUHC0) 密码: cngh**_
