---
title: Music
date: 2015-01-11 12:00:00
tags:
- Music
categories: Life
---

**Girls**
<iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="298" height="52" src="http://music.163.com/outchain/player?type=2&id=29848642&auto=0&height=32"></iframe>

**Ameno**
<iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="298" height="52" src="http://music.163.com/outchain/player?type=2&id=1300922&auto=0&height=32"></iframe

**序章ー球体を廻る青色の物語**
<iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="298" height="52" src="http://music.163.com/outchain/player?type=2&id=27586541&auto=0&height=32"></iframe

**ひまわりの約束**
<iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="298" height="52" src="http://music.163.com/outchain/player?type=2&id=28884238&auto=0&height=32"></iframe>

**わたがし**
<iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="298" height="52" src="http://music.163.com/outchain/player?type=2&id=698945&auto=0&height=32"></iframe>

**湘南が远くなっていく**
<iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="298" height="52" src="http://music.163.com/outchain/player?type=2&id=482022&auto=0&height=32"></iframe>
