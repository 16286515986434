---
title: VINCEC's Flickr Downloader / VINCEC Flickr 原图下载器
date: 2017-10-09 22:27:20
tags:
- Javascript
categories: Rice
---
Made a short javascript code that allows the user to unblock Flickr download limitation directly by linking to the biggest image source. Drag to bookmark in your browser for use.
制作了一个简短的 Script，可以按照提示点击来获取最大的图片，能用来绕过Flickr图片限制，建议拖动到书签在对应页面点击使用。

## Instruction / 使用方法
<iframe src="/res/unblock-flickr-downloader/index.html" allowtransparency="false" style="border:none;height:30px;width:50%"></iframe>_<-Drag the js code into bookmark and ready to use_
- **If drag does not work, make a blank bookmark and manually add this js code into url option.**
- Log into Flickr website. And click the bookmark when the image shown in the single page site _(eg. `https://www.flickr.com/photos/AUTHOR/PICTUREIDEX*****/`, [demo](https://www.flickr.com/photos/saablancias/37501006411/in/dateposted-public/))_
- Follow the instruction. Select the size and click again to get the source.
- _Notice: Firefox Nightly may can not use this method._

 <iframe src="/res/unblock-flickr-downloader/index.html" allowtransparency="false" style="border:none;height:30px;width:50%"></iframe>_<-在这里拖动到浏览器书签夹使用_
- **如果无法拖动，就新建一个空白书签，手动复制代码到url.**
- 登录Flickr, 在Flickr的对应单图页面点击书签中的 Unblock Flickr Downloader _(比如 `https://www.flickr.com/photos/AUTHOR/PICTUREIDEX*****/`, [demo](https://www.flickr.com/photos/saablancias/37501006411/in/dateposted-public/))_
- 按照提示点击多次， 选择图片尺寸，再次点击得到source。
- _在火狐nightly上可能无法使用。_

## Demo
<video src="https://i.imgur.com/jGdHPA1.mp4" autoplay="" muted="" loop="">
Your Browser does not support see this gif file, upgrade to Firefox 3.5+, Google Chrome, or Safari.
</video>