---
title: 呀趴里，寝室衣柜的结构我一个学期都不曾弄明白
date: 2015-01-15 15:00:00
tags:
- 随笔
categories: Life
---

今天又是曾经过了无数次的那个“放假前的一天”。在记忆里，往往“放假前的一天”过后就是“开学那一天”了。所以，那么多一堆堆的东西就根本不想去整，也不想去碰，只想静静的看着它们，，，无聊的发呆。

 迫不得已打开衣柜，发现整出来了好多从来都没印象的衣服，他们几乎完美的避开了一个学期一来我的所有视线，这些战士的使命就是在开学前一天被整齐的放进塑料袋，然后在放假前一天被主人（我）惊喜的发现，继而于次日重新踏上前往故土的征程。

 过了这么多年的“放假前的一天”到头来都是一个样，无论忐忑兴奋多少回也不会感到疲倦——结果在家里也还不是一个人无聊的逛B站，无论箱子里藏进多少书也不会觉得沉重——从来都掀不起那沉重的封面大概已是国际传统。过了这么多年的“放假前的一天”到了今天，唯一多了的是几分时光逝去的心酸泪![](http://bangumi.tv/img/smiles/tv/15.gif)，说好的我的青春呢，我咋不痛不痒的就已经过去了呢
