---
title: 推荐几款个人很喜欢的IOS应用好了
date: 2014-09-23 09:25:15
tags:
- MacOS
- IOS
categories: Path
---

不是土豪，都是限免时候收的，所以现在价格都回去了。推荐一个限免小站，我关注很久了，从伪三开始就一直有收藏——_[www.iapps.im](www.iapps.im)_。

<!--more-->

# Luminance

![](http://i.imgur.com/ua9Axu8.png "Luminance")

咋看是一款滤镜软件，其实不然，这些滤镜只是象征性的模版，操作自然异常简单，自定义能力相当强大，很喜欢这种style！

[https://itunes.apple.com/cn/app/luminance/id443465287?l=en&amp;mt=8](https://itunes.apple.com/cn/app/luminance/id443465287?l=en&amp;mt=8)

# Night Cam

![](http://i.imgur.com/XdpSI1v.png "night-cam")

拍摄夜景能力相当强悍，记住，是相当强悍！！
[https://itunes.apple.com/cn/app/night-cam-low-light-photo/id890834350?l=en&amp;mt=8](https://itunes.apple.com/cn/app/night-cam-low-light-photo/id890834350?l=en&amp;mt=8)

# Genius Scan+

![](http://i.imgur.com/NqSpzJK.png "Genius Scan+")

大概是这类扫描软件的鼻祖，虽然相似的已经收了一大堆，但还是最常用这个

[https://itunes.apple.com/cn/app/genius-scan+-pdf-scanner/id401818935?l=en&amp;mt=8](https://itunes.apple.com/cn/app/genius-scan+-pdf-scanner/id401818935?l=en&amp;mt=8)

# VonBruno Microphone Pro

![](http://i.imgur.com/3aOdytr.png "VonBruno Microphone Pro")

很实用的小软，接上音响就能把你的手机变成话筒，招新大会就是这小家伙派上了大用场
[https://itunes.apple.com/cn/app/vonbruno-microphone-pro/id346742386?l=en&amp;mt=8](https://itunes.apple.com/cn/app/vonbruno-microphone-pro/id346742386?l=en&amp;mt=8)

# Ångström

![](http://i.imgur.com/iCIJ3y2.png "Ångström")

这么非主流的名称，其实是一个转换单位的软件，而且不用你事先输入哪个领域，智能判断单位换算
[https://itunes.apple.com/cn/app/angstrom-unit-currency-time/id783839219?l=en&amp;mt=8](https://itunes.apple.com/cn/app/angstrom-unit-currency-time/id783839219?l=en&amp;mt=8)

`5/23/2016`

# 素记

![](http://i.imgur.com/Ku9gSLX.png "素记")

够文艺的日记方式，干净，看起来舒服，当然日记这种东西都是浮云，每一天过的舒服才是硬道理
[https://itunes.apple.com/cn/app/su-ji-yi-ri-yi-ji-hui-gui/id1070487377?l=en&mt=8](https://itunes.apple.com/cn/app/su-ji-yi-ri-yi-ji-hui-gui/id1070487377?l=en&mt=8)

# Quest: level up your life

![](http://i.imgur.com/GqV7Lr4.png "Quest")

打怪升级的todo应用，虽然自己已经装备升的挺高级了。。。
[https://itunes.apple.com/cn/app/quest-level-up-your-life/id947440899?l=en&mt=8](https://itunes.apple.com/cn/app/quest-level-up-your-life/id947440899?l=en&mt=8)
