---
title: 反正也没人看的看了也白看的 09，26和11
date: 2015-01-14 15:00:00
tags: 
- 随笔
categories: Life
---

不知道为啥，想起了历年的学号09，26还有11，过去，还会在课堂上将数字画成各种logo但现在7位的学号，尼玛，突然就没有兴趣这么做了。

shield tablet的事在早上已经搞定，放下手机的那一刻意味着我“一年内不买任何电子产品”的毒誓如约灰飞烟灭。这誓被打破也何不见得有啥坏的，本来也是一时兴起，但事实证明我就不是那样的人，同样是单单为自己找到那么一个高兴的点，享受个人爱好和遵守自己诺言，好像也没有什么本质区别。对自己毁约这样的事我倒是经常干的说，我真是对自己太不友好了。

晚上和小贤出去吃烧烤，一路妖风阵阵，一旁还多个妖人，我就预感定会有大事会发生，果不其然，烧烤店老板被风吹跑了，只留下空空的篷子在雨中悲鸣。没吃成，只能跑到村上的便利店捎上袋泡面回寝室继续得瑟。不过，在寝室里，泡上热腾腾的泡面，在来上一段B站上十年前伪科学揭秘视频，真是妙妙的人生。
