---
title: 换了新域名
date: 2014-08-26 18:31:26
tags:
- Blog
categories: Rice
---
[vincec.tk](http://vincec.tk) 一级域名显得高大上点，原来的 [vic.eu5.org](http://vic.eu5.org) 二级也会自动转跳过来啦。

主机方面依然使用免费主机，从原来的 [freewebhostingarea.com](http://freewebhostingarea.com) 换到 [hostinger.co.uk](http://hostinger.co.uk)，速度的确快了好多好多，还支持一键wordpress，安装插件也不再需要重复输入ftp密码，目前发现的问题就是有时会有一点小小的卡顿

（按着 [freehosting1.net](http://freehosting1.net) 的 Rating 试了好多好多的免费主机，赶在头炸之前被我蹭着一个还不错的~

` 9-3-2014 `

vincec.tk竟然给我无故注销了，这下不能再用了，于是又得换域名TuT

` 8-2015 `

买了Vince.top, 站由tinygrass.tk转了过来

` 5-2016 `
最后还是转移到了Github的page，用了hexo
