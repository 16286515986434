---
title: 相机的意义
date: 2016-05-26 03:03:45
tags:
- 随笔
categories: Life
---

记得自从去年8月开始拿到T10，现在已经快一年了，除了中途有一次误删了硬盘上的Macos分区中的照片，其他的都还留在hp的电脑里，手里目前的镜头一共有三只，套机自带的xf18-55，在eBay上47刀淘到的二手Helios 44-2， 家里只老凤凰Cosnio 50F1.7 外加两只转接环，似乎这样的装备拍拍已经戳手有余了，所以剁手环节打算就此停住。

喜欢摄影，能把美丽的瞬间定格似乎是件非凡的事情，也希望能看到别人投来对此赞许的目光，现在都放在flickr上了。他们总是说我的片子太过于平庸，第一眼看不出什么令人惊艳之处，但是看久了貌似还不错，然而我对此表示不屑。她说我只要不拍人其它都挺好，另外对我们俩的审美是否存在一致性进行了彻底的否决，然而这已经没有什么值得讨论的意义了，物是人非。大学死党说审美是大众对美的趋于一致的观点，我的对于美的独特理解只能说是一只异类，我应该从我的审美中保留别人认同的美，剥离异类的美，我表示很纠结。高中死党说管他们什么审美，他们看不懂你的片子就是不配欣赏你对美的理解，我对此表示认同并竖起了我的大拇指。

经历了很多事情，拍了很多照，当时的心情似乎也存进了EXIF。

有时渴望疯狂的举起相机，然而当按下快门的时候又迟疑了，会怀疑自己会不会为了拍照去经历，为了日后来感慨才去记录。最近这段时间有难度过啊，，，关于这一点（什么时候应该带上相机出门），我还是在思考中。。。

相机的意义大概就是增加一点我的小烦恼，留下一点小记录，让自己多些思考，多些钻研，停止盲从

![](https://c8.staticflickr.com/8/7439/27243956015_449cb6e4c5_b.jpg)
