---
title: Hello Sekai
date: 2014-07-17 23:43:13
tags:
- 随笔
categories: Life
---

Welcome to WordPress.

This is VinceC’s first post. Edit or delete it, then I am going to blogging!

![BACK](http://r.photo.store.qq.com/psb?/V10jRKWH1noV5t/SG4DdF1yPD.70cY1gx1TkSul37YfpIIlMN3k4YGb3Mc!/r/dFoBAAAAAAAA)

<embed height="1" allownetworking="internal" allowscriptaccess="never" width="1" invokeurls="false" src="http://www.xiami.com/widget/61405_1770763653,_1_1_FF8719_494949_1/multiPlayer.swf" type="application/x-shockwave-flash" wmode="transparent" />
