---
title: Online Vince Crypography Toolkits
date: 2016-12-05 14:22:06
tags:
- Javascript
- Crypography
- Made-bt-V
categories: Rice
---

- 12/25/2016
_Update Online tools for computing the inverse a^(-1) mod n with Fermat's Theorem or Euler's Theorem and Modular Exponentiations x^e with Square-and-multiply Algorithm._

- 12/22/2016
_Update an GCD calculating tool with parameters._

- 12/05/2016
_Make a small tools for Calculate order of all elements of multiplicative groups of Z(5), Z(7), Z(13), or others._

Try any and have fun!

<iframe src="/res/crypography-toolkit/index.html" allowtransparency="false" style="border:none;height:800px;width:100%"></iframe>
