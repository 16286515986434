---
title: 浏览器A到Z
date: 2014-07-19 23:49:47
tags:
- 生活
categories: Life
---
之前在别人的博客里有看到过，

在浏览器地址栏尝试输入A~Z，

可以看看近期的自己是在哪里四处游荡，浪费绳命 →_→

* **A** appleuser.com
* **B** baidu.com
* **C** coolapk.com
* **D** diandian.com
* **E** eu5org.freewebhostingarea.com/ftp/index.php
* **F** forum.xda-developers.com/
* **G** google.com
* **H** (无)
* **I** icloud.com
* **J** jiajixin.cn/
* **K** kulucolo.sinaapp.com
* **L** lofter.com
* **M** macx.cn
* **N** netcarshow.com
* **O** oeasy.org
* **P** pan.baidu.com
* **Q** qq.com
* **R** reliablecounter.com
* **S** sae.sina.com.cn
* **T** taobao.com
* **U** usingmac.com
* **V** vic.eu5.org
* **W** wordpress.org/themes/
* **X** xiami.com
* **Y** yinwang.org
* **Z** zealer.com
