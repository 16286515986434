---
title: 分享免费试用的日本vpn——vppn
date: 2014-08-04 05:10:38
tags: 
- 科学
categories: Path
---
最近没啥新发现，只找到了一个免费又好用的vpn，[vppn](http://vppn.us/)

点击链接，简单的填入你的邮箱，就能获得免费试用一天的免费日本vpn，而且没有流量限制哟

（另外很喜欢该网站“没有设计”的设计，很有日式风有没有）
