---
title: Chrome 新 UI
date: 2018-08-01 10:53:03
tags:
- 教程
categories: Config
---

Chrome 最近好像有新UI，我对之前的UI真是难以忍受，，真不敢相信这么久了才改

开启方法：
1. 输入 [chrome://flags](chrome://flags)
2. 搜索 `UI Layout for the browser's top chrome`
3. 改成 `refresh`

嗯，顺眼多了

![](https://i.imgur.com/xoWxm6O.png)