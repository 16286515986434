---
title: 能使你的Windows字体显示效果接近Mac？
date: 2014-08-05 04:45:15
tags:
- Windows
categories: Path
---
看到标题，肯定不少人就知道了今天我想分享的小软——MacType

Vic我用这个的确是有段时间了，大概有2年了，而且曾经一度反反复复地设置，为了找到好看的字体显示效果，盯着屏幕半天导致眼花头晕，如今早已释然，用着预设，头不痛，就挺好。

网上对MacType的介绍也有不少，我就挑些我个人觉得重要的讲讲

<!--more-->

我们都知道，Mac显示效果出众是因为Mac的retina分辨率极高，这样便能将好几个像素点完全利用起来来渲染显示一个单独的字；但通样的情况到Windows这就悲剧了，因为windows的各种屏幕尺寸参差不齐，无法像mac那样很好的制定一套整合像素渲染的规则，所以可以说在windows下分辨率越高，显示的效果越惨不忍睹，自带的 Clear Type 也起不了决定性的作用。故 MacType 出现了，它能增加周围像素点的阴影渲染，预设了伽马参数，使得字体看上去尽量的清晰饱满，但也只是假装看起来渲染了多了些，就是个加强版的Clear Type，还是不能解决Windows字体渲染弱的根本问题。

Win7/8的时候我往往会采用注册表加载的显示方法已获得最无缝的享受，但Win8.1加入注册表后貌似会有些小问题，常常几个小弹窗什么的很不好看，有问题的童鞋们不妨采用Mac Tray托盘加载的兼容加载模式并勾上管理员权限，一路用下来都没怎么操心

总之，采用xMac的xHei+xSong后，Windows上的1080p屏看上去总算是没那么不堪了

**[下载](http://tieba.baidu.com/p/2793398021)**

**[转跳贴吧以加强学习](http://tieba.baidu.com/f/good?kw=mactype)**
