---
title: MAC上的后期相关APP来一发
date: 2014-08-22 01:43:55
tags:
- MacOS
categories: Path
---
很久没写点有用的东西了，唉，但最近也没学点有用的东西，只能翻Vic我这一年来在Mac上发现的那些后期相关的app。

<!--more-->

# FCP Motion Compressor 三剑客

![FCP](http://m3.img.srcdd.com/farm5/d/2014/0821/23/A9EE5FDF0A75094D8314E1A7E8D2CBB9_LARGE_278_240.png)

![Motion](http://m3.img.srcdd.com/farm4/d/2014/0821/23/150F94EC52A4DF357455C7D87D71E151_LARGE_228_246.png)

![Compressor](http://m3.img.srcdd.com/farm4/d/2014/0821/23/3BAE4F4D0F7B25CFBE662B1BECDB94E7_LARGE_256_232.png)
一说起Mac平台，那就不得不提这个当初害我心动的罪魁祸首了，在从黑苹果再到虚拟机并尝试了所有能用的方法依然无法品尝到FCP的情况下，不得已，一咬牙，入了个日版Macbook。。。

事实证明，它的确也很是方便。简洁明了的界面真的很容易上手，讨喜的动画效果也着实让人把玩的爱不释手，非常适合用半业余的水平作出一部大片来扮猪吃老虎。丰富的预设，Mac平台独占，三款软件间的配合也一如既往保持了苹果应用的稳定。但，却也正是在Mac上的独占，使他成为注定小众的命运。

**缺点**：

1. 因为使用和处理方面和普通非编很不一样，是靠前后排序一般的改变位置而连成的，这就会出现一个问题，时常前一个视频片段因为被拖走或删除而使后面所有时间轴乱了套，因为没有像pr那样默认的黑场背景，（我的处理方法通常是用拖个预设的黑场下来剪成合适的大小后替换前面要移动的视频。需要一定的学习成本
2. 第一次使用容易被导出按钮弄的摸不着头脑，不线型的导出速度也是个令人头疼的地方。

# Adobe 阿逗比家族

![null](http://m2.img.srcdd.com/farm5/d/2014/0821/23/EC5B5AA9083E35B5AAF1ADF0C0976903_LARGE_2426_312.png)
不用多说了，鼎鼎有名的逗比家族。可其最早竟也是在Macintosh发布的，真是醉了。

它们的优点就是统一化，切开一切花哨的功能，将最本质也是最基础最碎片化的功能带给使用者，工作区分明，这样一来长时间工作妈妈也不用担心会审美疲劳了。当然，可别小看这些最简单最基础最繁多的设置工具，没有了那些预设，伟大的创造家们依旧能做出最独一无二的个性化影片，而且相比起FCP，只有更加强大。

# PluralEyes 3

![null](http://m3.img.srcdd.com/farm4/d/2014/0821/23/8737AE8D718C6F4305AC62293F91C9C2_LARGE_250_240.png)
明显的，我们拍摄时不可能只有一个话筒取材，PluralEyes 能实现多个机位音轨的智能匹配，相当聪明和方便（对波形什么的简直不是人干的

# Final Cut Library Manager

![null](http://m1.img.srcdd.com/farm5/d/2014/0821/23/7175E45240CD31F69189B7E0AA825619_LARGE_354_236.png)
检查硬盘中的所有FCP项目（工程）文件，并清楚的列出，为你省下宝贵的硬盘容量（SSD好尊贵。。

# 7toX for Final Cut Pro

![null](http://m2.img.srcdd.com/farm5/d/2014/0821/23/C0137F447D35496CD65BF2BE6777E71F_LARGE_328_244.png)
平常的，我们经常遇到在PR或者AE做了一半又或是PC上用了Vegas，想转至FPC，在之前这几大后期软件总是能导出为Final Cut Pro 7支持的XML，所以也不是什么难事，但Final Cut Pro X 悲剧的不支持7版本的文件了。7toX for Final Cut Pro 就能很完美的将7的XML文件转成X版的，绝大多数效果都是相当完美的

# iSerial Reader、KCNScrew

![null](http://m3.img.srcdd.com/farm4/d/2014/0821/23/3AAC686DDF70BC065AB48D11F00694E7_LARGE_216_224.png)![null](http://m1.img.srcdd.com/farm5/d/2014/0821/23/8021A6500E3C01251720C6B4CA330876_LARGE_640_240.png)
再推荐两个软件序列号共享的应用，你懂得

# 部分下载地址

**_链接: [http://pan.baidu.com/s/1nt9fxYp](http://pan.baidu.com/s/1nt9fxYp)_**

**_密码: enln_**
