---
title: A html page with tab features
date: 2016-08-15 16:40:18
tags:
- Javascript
categories: Rice
---

Make a practice with javascript code to simulate a web page with tab features and allow the user to manipulate the tabs.

The code first will recognize the items in the array and classify them into different containers. Then the user needs to link each tab with related pages manually in the getURL() method.

The way of import of items array and import of relationship links should be upgraded in the future.

[The demo page](/res/table-tree/index.html "table-tree-demo").
