---
title: 将你家的路由器“串联”起来吧
date: 2014-07-19 05:53:21
tags: 
- 教程
categories: Rice
---

因为家里的路由器是接在客厅的，离我的房间有点远，这样就导致了每次躺在床上都得伸直了胳膊才能加载手机B站，-`д´-实在倍感痛苦。

今天突发奇想，为啥不能将两个路由串起来呢，一试还真的成功了：
<!--more-->

1. <del>我先重置了下家里剩的旧路由，（心想着到时不行再改动态静态之类的协议也不迟</del>
2. <del>如图，拉了根主路由的线接到次路由的任意接口（事实证明我的WAN或子接口都行）<span style="background-color: #000000;"><span style="color: #000000;">子接口 </span>（这里是因为曾经在宾馆等公共场所看到过如此接法，因为第一个路由器已经输入了ADSL的账户密码，不必再接WAN路了</span></del>
3. <del>尝试搜索了它发出的信号，嘿！一次就成功！并且再拉条网线到电脑也是没有问题嗒！</del>
4. <del>断开重新设置WIFI名称和密码（这里需要注意下，如果想正常进入192.168.1.1，最好首先断开那根主路由的线并断下电源，这样单独设置次路由的账号和密码会比较妥当</del>
5. <del>重新连接主路由的线，如同之前一样</del>
6. <del>Perfect~接下来享受路由器所带来的无与伦比的B站享受吧~</del>

`7-23`

有些地方发现还是有时候会出问题，比如两个路由同时开会抽，网上搜索了下有没有遗漏，现在重新放上步骤：

* 重置新路由
* 单独进入192.168.X.X(d-link是192.168.0.1，出厂账号为Admin，密码为空；tp-link是192.168.1.1，出厂账号和密码都为admin)
* 设置DHCP服务器—DHCP服务，不启用 “DHCP服务器”，单击“保存”，如下图1
* 手动重启路由器
* 拉根主路由的子接口线至此路由（至于接口就拉到子接口好了，如下图2）

![图一](http://m1.img.srcdd.com/farm5/d/2014/0903/11/03C09C2343C48208DC3DDA8C28ABDE28_LARGE_500_375.jpeg)

![图二](http://m1.img.srcdd.com/farm5/d/2014/0903/09/6BBA33475ECCCF7F19FFE7AEAE755065_LARGE_800_449.jpeg)
