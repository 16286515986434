---
title: KuluColo的完善日记
date: 2014-08-13 09:43:20
tags:
- KuluColo
- Anime
categories: Rice
---
`2014/8/13`

*   增加了首页显示浏览人数以及每篇文章开头显示浏览人数的代码[
![20140813174054](http://m2.img.srcdd.com/farm5/d/2014/0903/11/099CD12938547211B6E142ED65990C9E_LARGE_1721_224.png)](http://m2.img.srcdd.com/farm5/d/2014/0903/11/099CD12938547211B6E142ED65990C9E_LARGE_1721_224.png)
*   增加Google XML Sitemaps插件，听说能改善google收录，但 baidu XML 貌似根本没用，算了，聊胜于无
*   发现站内搜索时不能搜索 Tag ，而点击 Tag 却又不是搜索页面，这不是完全分开来了嘛，但不想再另加插件了，于是稍加改之（增加人工温馨小提示，真是好囧[![QQ图片20140813173957](http://m1.img.srcdd.com/farm4/d/2014/0903/11/D37ACBF3057E0B7AA9E58E992DA000EC_LARGE_1660_312.jpeg)](http://m1.img.srcdd.com/farm4/d/2014/0903/11/D37ACBF3057E0B7AA9E58E992DA000EC_LARGE_1660_312.jpeg)

`2014/8/20`

*   收到了google Adsense的邮件通知，好兴奋
*   拿到了广告代码，研究中
