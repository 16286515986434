---
title: 辛苦弄起来的点点图床都跟别人跑啦
date: 2016-05-20 03:44:07
tags:
- KuluColo
- Blog
categories: Life
---

原来一直以为[kulucolo](http://kulucolo.github.io "kulucolo")上的图是因为在美肯所以没法显示，回来了以后才发现点点早就已经人去楼空，一片死寂。

![](http://i.imgur.com/QaqpHgG.png '现在的点点')

真是好惨，辛苦弄的图库全没了，包括这个博客还有kulucolo的无一幸免。以为这么大一个轻博客网站应该不会这么坑的突然消失，结果所有图片都没有留在本地，网上看到网友都在说去年8月开始就访问不正常了，结果到现在连个通知也没有。果然还是应该用大点的国外的什么的，现在用的是[imgur](https://imgur.com "imgur")
