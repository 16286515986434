---
title: 若干软件通用密钥
date: 2014-09-03 00:21:06
tags: 
- 技巧
categories: Path
---

# WP plugin —— Akismet

API Key: `dcd8afdf59b5`

# iFFmpeg (version 3.9.17)

Activation code: `2E9F-DEU5-T9JP-E7B4-UTAM-CD4E`
