---
title: Swift3
date: 2017-1-5 16:23:11
tags:
- Swift
categories: Rice
---
- 1/5/2017 update a new version of asd_teaching, including recording voice features, increasing the multiple slides, and cleaning cache features. [Github Code](https://github.com/vincecao/ASD_teaching)

## new demo of ASD_Teaching for IOS
{% youtube y3y5HCOxzUo %}
<!--more-->
---
- 11/1/2016
Due to needing to create ios app so try to learn some swift knowledge, however, the swift 3 is so different from swift 2 which caused hard for me such a beginner to find the answer of help online. Any way tried some of the code and realize a small asd_teaching app with some basic features.

Till now the voice is recorded in advanced in the app, and if the object name is paired with the existed audio name, it will play. The next step will be to add record features and save the pattern of each picture into the app's library function. AVAudioRecorder in swift3 is still a mystery for me...

![](http://i.imgur.com/2407H5c.jpg)

## small demo of ASD_Teaching
{% youtube I0yiv5PWyiI %}