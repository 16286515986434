---
title: 淘宝了一些东西
date: 2018-08-05 11:00:08
tags:
- 剁手 / Chopping Hands
categories: Life
---

马上要回去读书了，临走前淘宝一波

## 一个键盘掌托

![](https://i.imgur.com/ADB089o.png)

弄了个绿檀的，手臂现在香香的每天，还定制了EVA的logo，太帅了简直

![](https://i.imgur.com/xKKp9Ma.jpg)
![](https://i.imgur.com/1FENEOF.jpg)

## 一个帅气的搭电宝

为了随身带着个，我决定不带自己的充电宝了，真是巨大的牺牲

![](https://i.imgur.com/RwOB76b.jpg)
![](https://i.imgur.com/i3dc7wC.jpg)
![](https://i.imgur.com/GlhmaPj.jpg)

## 一个手机壳

到手有点味道的，最最普通的苹果手机壳。现在8p手机壳怎么背面镜头和壳一样后啊，那镜头不是每次都得被桌面磨，好气

## 一个s9的原配耳机

本来一直心水索尼的原配耳机，不过现在长得真是越来越难看了，就弄了三星的，淘宝一堆三星的，后来看了个帖子，意思是便宜的是仿的，就弄了一个100出头的。听起来音质还是不错的，期待能和骚尼 xperia z 原配那个一样用个 4，5年。话筒好像比较敏感，录音有点脆，没有骚尼之前的好。

3天后更新：右耳机在飞机上因为倒翻了酸奶英勇牺牲了，果然以后还是买骚尼。。。。