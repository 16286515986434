---
title: Macbook 日版键盘终极解决方案（伪）—— Karabiner
date: 2018-06-15 13:17:55
tags: 
- MacOS
categories: Config
---

自从15年买来 MacBook 后就自己在上面捣鼓了很多东西，其中之一就是想方设法从日式键盘解脱出来。日版虽然让我便宜了近2000，但键盘真是不能忍。

日式键盘当时买的人还不多，现在感觉也不多，毕竟异形键位，很快我就在当时发现了一个解决方案，3年的实际体验发现这个方法还是非常靠谱而且方便的, 在与正常 Windows 键盘之间来回切换也没有明显的不适，倒是我现在都不会用美版的  Mac 键盘了【噗， 3年真够长的。

还有个有趣的地方就是，13 Late款日版的 Macbook Pro 键盘个人打起来比美版的要舒服， 个人尝试了下，日式键盘中间凹的弧度更小，边缘更圆滑，`F`键和`J`键顶部的定位按钮也更温和。美版 13 Late 打起来感觉怪怪的，容易割手。当然，显而易见，无论美版还是日版，都肯定要比现在的那蝴蝶手感要好上100倍的。

![](https://i.imgur.com/g79uzem.jpg)

# 方法

这里要用到一个软件，非常强大，**Karabiner**, [官网地址](https://pqrs.org/osx/karabiner/)。

![](https://i.imgur.com/zljyU92.png)

Karabiner 在之前10.12出来后有一次比较大的更新，所以安装包是不一样的。如果是10.12或者之后的话，我们下载下来一顿操作就非常方便的安装好了。直接打开它就发现，`shift + 2` 已经可以顺利显示 `@` 而不是 `“` 了。记忆中，如果是以前的版本的 karabiner，还得在设置里面选择_“japanese-keyboard-to-us-keyboard”_ 的固定配置文件。

# &#768; 和 &#126; 键设置

现在我们再来设置下写代码比较重要的 **&#768;** 和 **&#126;** 键了,笔者按图中方法绑定了自己喜欢的手感。

![](https://i.imgur.com/RW3heHE.png)

好了，现在就是纯正的美式键位了，和键盘上的记号有相当大的出入。少年们，开始使用你们的记忆进行特殊字符的盲打吧。

# 你们要的最终解决方案

喜闻乐见，最终解决方案当然就是花钱去换个 US 键盘的 C 面啊。