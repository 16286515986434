---
title: 无题
date: 2015-01-30 09:00:00
tags: 
- 随笔
- 生活
categories: Life
---

> 懦夫，连幸福都会害怕，碰到棉花也会让人受伤，有时也会被幸福所伤.
> ———《人间失格》

/

> 「⋯⋯人生在世何其痛苦，所以咖啡至少该甜一点。」

/

> 「就结局看来，我们只是在那个黏答答的群体中待一会儿，之后又回到各自的容身之处。这是一生仅有一次的缘分。」

/

> 「就某方面来说，我觉得手机会让人更快陷入孤独。有人打来时，可以选择不接听或不管它；收到的邮件放着不回，它就会继续躺在信箱里。人际关系开始出现取舍的选择后，沟通会随每个人的心情时有时无。」

/

> 「该怎么说呢？这就是一生一次的缘分。既然有相遇，也会有分别。」

/

> 「对自己方便的事物里有内幕的。这是常识」

/

> 「没发生的假定毫无意义。
>
> 人生没有如果。
>
> 惟有后悔才是人生。
>
> 明明本该如此，」

/

> 「人总是只看自己想看的。」

/

> 「就像老话说得 授人以渔 他能吃一天
授人以有500000种配置选择的菲亚特500 他这辈子都看不完宣传册了

>As the old saying goes, give a man a fish and he will eat for a day. Give a man a Fiat with 500,000 choices and he won’t work out the brochure in one lifetime.」

>——Cars of The People

/

> 「对现状的失落，归根到底可以总结为对自己无能的失望」

/

> “如果一艘船不知道该驶去哪个港口，那么任何方向吹来的风都不会是顺风。”

> by 《塔木德》

/

> 「不为人脉而交浅言深，不因他人缺点而论人非」
