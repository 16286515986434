---
title: “手把手”教你WKU闪讯连接WIFI
date: 2014-08-31 04:57:25
tags:
- 教程
categories: Rice
---
*`2015-9`
	**原方法已失效，现已搭配 OpenWrt－2.4GHz－7DD475 固件路由器破解***

很多人来问我如何破解闪讯装wifi，

于是，我就义不容辞的放上图解教程，图示很详细，小白也都不怕不怕啦

注：此操作也许有未知风险，请谨慎操作，出现财产数据损失博主对此概不负责
<!--more-->

# 教程

1. 首先你要有一个路由器，要求是单根线老版的，因为双天线的要刷回旧版固件才可以用，最好是按我的推荐买，目前**TL-WR740N**， **TL-WR724N**，还有小编自己从家里带来的老古董**TL-WR340G+**都是可以使用的，其他型号其他品牌暂不清楚。

2. 重置路由器，连接上无密码的网络.![](http://m2.img.srcdd.com/farm5/d/2014/0831/19/4DF3667377965B2E0BE979561AD92562_LARGE_481_107.jpeg)

3. 进入[http://192.168.1.1，账户/密码都是admin](http://192.168.1.1，账户/密码都是admin).![](http://m2.img.srcdd.com/farm4/d/2014/0831/19/3084E0F8BEE68672B5B690E5FD674DDC_LARGE_879_805.jpeg)

4. 按着图示操作![](http://m3.img.srcdd.com/farm5/d/2014/0831/19/65371975E1EB4F667DCCCB0F2E1DDB50_LARGE_880_804.jpeg)![](http://m3.img.srcdd.com/farm5/d/2014/0831/19/E84A6A737E963C3F482EBBEE6848E3A9_LARGE_880_804.jpeg)

5. 修改默认无线的名称和密码![](http://m3.img.srcdd.com/farm4/d/2014/0831/19/7EA4500D24419F9DD2217E42508E6655_LARGE_880_804.jpeg)![](http://m1.img.srcdd.com/farm5/d/2014/0831/19/1985E7AAE9B9DFD3BA5E83257C4B177F_LARGE_880_804.jpeg)

6. 路由器设置成功，等待重启![](http://m2.img.srcdd.com/farm4/d/2014/0831/19/A0A07A37E066DA713A6DAADBE5EEFAB8_LARGE_880_804.jpeg)

7. 连接到设置好的无线网络![](http://m1.img.srcdd.com/farm4/d/2014/0831/19/8FAACD868BC1EE0803D54E88604C7860_LARGE_462_262.jpeg)

8. 解压本篇文章附件中闪讯破解版，打开bin目录下的NetKeeper.exe![](http://m1.img.srcdd.com/farm4/d/2014/0831/19/B23E705ADC3417E4836DF160D410FB47_LARGE_1217_766.jpeg)

9. 又看到这个熟(sang)悉(bing)的界面了吧，点连接![](http://m1.img.srcdd.com/farm4/d/2014/0831/19/F7896851C754742A1359147741067373_LARGE_380_626.jpeg)

10. 大功告成，连接成功，点击“继续按钮开心的上网吧，完全关掉闪讯的后台程序后也丝毫没有问题![](http://m1.img.srcdd.com/farm5/d/2014/0831/19/92A457FE7864CC5405ADF2BADB816071_LARGE_700_450.jpeg)

# 可能会出现的问题界面


1. 第一种，点击出现改图样式，说明没有连接上互联网![没有连接上网络](http://m2.img.srcdd.com/farm4/d/2014/0831/19/46DD542E911F2BF517A981258BE59E4E_LARGE_700_450.jpeg)

2. 第二种，不能正常按下“连接按钮的其他情况![](http://m2.img.srcdd.com/farm5/d/2014/0831/19/478F155DBC08C0EC93AA873C5E7A5E30_LARGE_380_626.jpeg)![](http://m3.img.srcdd.com/farm5/d/2014/0831/19/AEE8F0D1114FFB8CA05EAC512691F732_LARGE_380_649.jpeg)![](http://m2.img.srcdd.com/farm5/d/2014/0831/19/0F27585D5040739C232C93E457E7FF23_LARGE_380_626.jpeg)![](http://m1.img.srcdd.com/farm5/d/2014/0831/19/4DC25C65100AA3300E0FB96C1EA27312_LARGE_380_626.jpeg)![](http://m2.img.srcdd.com/farm4/d/2014/0831/19/088AA82A33096AA4F437FF71BC6D17C9_LARGE_605_222.jpeg)![](http://m2.img.srcdd.com/farm4/d/2014/0831/19/83E5F0D0464749FBDF1A1A9AA6EF4C62_LARGE_196_202.jpeg)![](http://m1.img.srcdd.com/farm4/d/2014/0831/19/74E3C9D1063FB33011A0771599940EEF_LARGE_196_202.jpeg)

# 解决方法

1. 确认完全关闭原有闪讯程序

2. 确认网线没有被室友剪断，或者网线没有插错插孔，再不行就直接换根网线

3. 确认其他设备已全部从账号上断开（包括手机和电脑）

4. 确认上网账户有钱滴！

5. 实在不行就再重新操作一遍，然后再重新下附件，然后，，就没有然后了:&gt;

# 附件分享地址

**链接**: _[http://pan.baidu.com/s/1sjnYLvn](http://pan.baidu.com/s/1sjnYLvn)_ **密码**: nxbe
